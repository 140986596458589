<template>
  <div class="login-page">
    <img class="logo-bg-image" :src="require('../assets/images/login/bg.svg')" alt="logo bg" />
    <a-row class="login-page-row">
      <a-col :xs="0" :sm="12" class="logo-block-wrapper">
        <img class="sec-image" :src="require('../assets/images/login/left-base.png')" />
        <img class="logo-image" :src="require('../assets/images/logo/logo_dom-white.svg')" />
        <img class="center-image" :src="require('../assets/images/login/left-mid.png')" />
        <div class="title-1">A Launchpad to Your Success !</div>
        <div class="title-2">
          Hearing customers voice clearer and faster, seethrough competitors’ strategy to ensure your business success.
        </div>
      </a-col>
      <a-col :xs="24" :sm="12" class="login-form-wrapper">
        <a-form class="login-form" :model="formState" layout="vertical" @finish="handleSubmit" @submit.prevent>
          <div class="form-title">
            <span class="blue-text">Reset</span>
            <span>Password</span>
          </div>
          <a-form-item v-if="result === 'success'">
            <a-alert type="success" show-icon closable @close="result = null">
              <template #description>Password reset successful <br />Please check you email</template>
            </a-alert>
          </a-form-item>
          <a-form-item v-if="result === 'error'">
            <a-alert type="error" show-icon closable @close="result = null">
              <template #description
                >The account you entered does not exist.<br />
                Please double-check and try again or <br />
                <a href="#">contact support </a></template
              >
            </a-alert>
          </a-form-item>

          <a-form-item name="password" label="Password" class="mb-0">
            <a-input-password
              v-model:value="formState.password"
              @change="result = null"
              @keyup="validateNewPass"
            />
          </a-form-item>

          <div class="pass-criteria">
            <p
              :class="{
                active: activeCriteria,
                valid: validNewPassCombination,
              }"
            >
              <FeatherIcon type="circle" :size="10" />Must contain at least one
              lowercase letter, one uppercase letter, and one number
            </p>
            <p :class="{ active: activeCriteria, valid: validNewPassLength }">
              <FeatherIcon type="circle" :size="10" />Must be at least 8
              characters long
            </p>
            <p :class="{ active: activeCriteria, valid: validNewPassChar }">
              <FeatherIcon type="circle" :size="10" />Allowed special
              characters: ! @ # $ % ^ & * ( )
            </p>
          </div>

          <a-form-item name="confirmPassword" label="Confirm Password" class="mb-0">
            <a-input-password
              v-model:value="formState.confirmPassword"
              @change="result = null"
              @input="validateConfirmPass"
            />
          </a-form-item>

          <p v-if="!validConfirmPass" class="invalid-notice">
            {{ warningConfirmPass }}
          </p>

          <a-form-item>
            <a-button
              class="login-button"
              htmlType="submit"
              type="primary"
              size="large"
              :disabled="!isValid"
            >
              {{ isLoading ? 'Loading...' : 'Reset Password' }}
            </a-button>
          </a-form-item>

          <a-form-item>
            <span>Return to</span><router-link to="/login"> Login</router-link>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/services/api';

const securePassRegExp = new RegExp('((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)+$');

export default {
  setup() {
    // const { dispatch } = useStore();
    const isLoading = ref(false);
    const result = ref(null);
    const route = useRoute();
    const resetToken = ref(route.query.token);
    const router = useRouter();

    const formState = reactive({
      password: '',
      confirmPassword: '',
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      if (formState.password && formState.confirmPassword) {
        let response = await api.resetPassword(resetToken.value, formState.password).catch(() => {});
        if (response && response.code === 200) {
          result.value = 'success';
          formState.password = '';
          formState.confirmPassword = '';
          setTimeout(() => {
            router.push({ name: 'login' });
          }, 1000);
        } else {
          result.value = 'error';
        }
      } else {
        result.value = null;
      }
      isLoading.value = false;
    };

    const validNewPass = ref(true);
    const activeCriteria = ref(false);
    const validNewPassLength = ref(false);
    const validNewPassCombination = ref(false);
    const validNewPassChar = ref(true);
    const validateNewPass = (e) => {
      activeCriteria.value = true;
      if (e.target.value.length < 8) {
        validNewPassLength.value = false;
      } else {
        validNewPassLength.value = true;
      }
      if (securePassRegExp.test(e.target.value)) {
        validNewPassCombination.value = true;
      } else {
        validNewPassCombination.value = false;
      }
      // hardcode symbol list '='
      // use regex with full list later
      if (e.target.value.includes('=')) {
        validNewPassChar.value = false;
      } else {
        validNewPassChar.value = true;
      }
    };

    const isValid = computed(() => {
      return (
        validNewPassLength.value &&
        validNewPassCombination.value &&
        validNewPassChar.value &&
        validConfirmPass.value
      );
    });
    const validConfirmPass = ref(false);
    const warningConfirmPass = ref('');
    const validateConfirmPass = (e) => {
      if (e.target.value) {
        validConfirmPass.value = true;
      } else {
        validConfirmPass.value = false;
        warningConfirmPass.value = 'The confirm password is required.';
      }
      if (formState.confirmPassword !== formState.password) {
        validConfirmPass.value = false;
        warningConfirmPass.value =
          'The password and confirm password do not match';
      }
    };

    return {
      resetToken,
      result,
      isLoading,

      handleSubmit,
      formState,

      validateNewPass,
      activeCriteria,
      validNewPassLength,
      validNewPassCombination,
      validNewPassChar,
      validNewPass,

      warningConfirmPass,

      isValid,
      validateConfirmPass,
      validConfirmPass,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../config/theme/colors.json';
.login-page {
  background: #495c83;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  padding: 76px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  .logo-bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    max-width: 100vw;
    height: auto;
    // height: 100vh;
    // max-height: -webkit-fill-available;
    // max-width: 100vw;
    overflow: hidden;
  }
  .login-page-row {
    z-index: 100;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    .logo-block-wrapper {
      z-index: 100;
      background: linear-gradient(90deg, #283457, #495482);
      color: #fff;
      padding: 0 !important;
      position: relative;
      .sec-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-image {
        position: absolute;
        height: 30px;
        width: auto;
        top: 30px;
        left: 30px;
      }
      .center-image {
        position: absolute;
        width: 80%;
        max-width: 443px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
      }
      .title-1 {
        position: absolute;
        width: 100%;
        bottom: 128px;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        padding: 15px;
        text-align: center;
      }
      .title-2 {
        position: absolute;
        width: 100%;
        bottom: 50px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 15px;
        text-align: center;
      }
    }
    .login-form-wrapper {
      z-index: 100;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .login-form {
    width: 360px;
    max-width: 80%;
    .form-title {
      margin-bottom: 48px;
      font-size: 24px;
      font-weight: 500;
      .blue-text {
        color: #3371ff;
        margin-right: 4px;
      }
    }
    .ant-form-item-label > label {
      color: #666d92;
      font-size: 14px;
      letter-spacing: 0.08em;
    }
    .ant-input {
      border-radius: 4px;
    }
    .login-button {
      width: 160px;
      margin-top: 32px;
    }
  }

  // xs only
  @media only screen and (max-width: 576px) {
    padding: 15px;
    .login-form {
      padding: 20px;
    }
  }
}

.pass-criteria {
  margin-top: 10px;
  p {
    font-size: 12px;
    line-height: 15px;
    color: #9299b8;
    i {
      margin-right: 5px;
    }
    &.active {
      color: #ff4d4f;
    }
    &.active.valid {
      color: #20c997;
      i svg {
        fill: #20c997;
      }
    }
  }
}
.invalid-notice {
  font-size: 12px;
  margin-top: 5px;
  color: #ff4d4f;
}
</style>
